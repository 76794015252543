import React, { useState, useCallback, useEffect } from "react";
import { Input } from "reactstrap";
import { getDateTimeFormatted } from "../../../../_helpers/DateFormatHelper";
import { ToggleWithSync } from "../../Airframe/Toggle/ToggleWithSync";
import { Clock } from "../Fichas/Programs/components/Clock/Clock";
import { Days } from "../Fichas/Programs/components/Days/Days";

const states = {
  IDLE: "IDLE",

  ERROR_PROGRAM_24H: "ERROR_PROGRAM_24H",
  ERROR_DAYS_NOT_SELECTED: "ERROR_DAYS_NOT_SELECTED",
  ERROR_TOO_MUCH_ACTIVATIONS: "ERROR_TOO_MUCH_ACTIVATIONS",
};
const initState = {
  state: states.IDLE,
  trigger_data: {
    active: true,
    weeklyChecks: true,
  },
};

export const TriggerFormView = (props) => {
  const {
    trigger,
    onTriggerModified,
    onSave,
    onDelete,
    onSetupActivations,
    allowLoopOption,
  } = props;

  const [state, setState] = useState(initState);

  //#region methods
  const loadDataFromTrigger = useCallback((trigger) => {
    setState((prev) => ({
      ...prev,
      trigger_data: trigger || initState.trigger_data,
    }));
  }, []);

  const onSaveInternal = useCallback(() => {
    onSave(state.trigger_data);
  }, [onSave, state.trigger_data]);

  const onDeleteInternal = useCallback(() => {
    onDelete(state.trigger_data);
  }, [onDelete, state.trigger_data]);

  const onTriggerModifiedInternal = useCallback(
    (trigger) => {
      onTriggerModified(trigger);
    },
    [onTriggerModified]
  );
  //#endregion

  //#region components

  const canBeSaved = useCallback(() => {
    let validDays = state.trigger_data?.days > 0;
    let validStartTimestamp = state.trigger_data?.startTimestamp;
    let validSubprograms =
      state.trigger_data?.subprograms instanceof Array &&
      state.trigger_data?.subprograms.length > 0;
    return validDays && validStartTimestamp && validSubprograms;
  }, [
    state.trigger_data?.subprograms,
    state.trigger_data?.days,
    state.trigger_data?.startTimestamp,
  ]);
  //#endregion

  //#region clock && days

  const parseHourMinutesSecondsToSeconds = useCallback(
    (hour, minute, seconds) => {
      return (hour || 0) * 60 * 60 + (minute || 0) * 60 + (seconds || 0);
    },
    []
  );

  const parseSecondsToHourMinutesSeconds = useCallback((segundos) => {
    const horas = Math.floor(segundos / 3600);
    const minutos = Math.floor((segundos % 3600) / 60);
    const segundosRestantes = segundos % 60;

    return [horas, minutos, segundosRestantes];
  }, []);

  const onHourMinuteChanges = useCallback(
    (seconds, minute, hour) => {
      let startTimestamp = new Date();
      startTimestamp.setHours(hour, minute, 0, 0);

      const newState = {
        ...state,
        trigger_data: {
          ...state.trigger_data,
          modified: true,
          startTimestamp: startTimestamp.toISOString(),
        },
      };
      setState(newState);
      onTriggerModifiedInternal(newState.trigger_data);
    },
    [onTriggerModifiedInternal, state]
  );

  const getClockComponent = useCallback(() => {
    let hour = "12";
    let minute = "00";
    if (state.trigger_data?.startTimestamp) {
      const hourMinuteSplited = getDateTimeFormatted(
        new Date(state.trigger_data.startTimestamp)
      ).split(":");
      hour = hourMinuteSplited[0];
      minute = hourMinuteSplited[1];
    }

    return (
      <Clock
        SetHour={onHourMinuteChanges}
        minMinutes={0}
        initHour={hour}
        initMinute={minute}
        title={<div className="HourStartIn">Hora de inicio:</div>}
        maxHours={23}
        maxMinutes={59}
        maxMinutesWhenMaxHoursReached={59}
        onMaxHourSetMinutesToMax={true}
        applyMaxMinutsOnlyWhenMaxHoursReached={true}
        enabled={true}
      />
    );
  }, [onHourMinuteChanges, state.trigger_data?.startTimestamp]);

  const onEndHourMinuteChanges = useCallback(
    (seconds, minute, hour) => {
      const duration = parseHourMinutesSecondsToSeconds(hour, minute, seconds);
      const newState = {
        ...state,
        trigger_data: { ...state.trigger_data, modified: true, duration },
      };
      console.log(newState);
      setState(newState);
      onTriggerModifiedInternal(newState.trigger_data);
    },
    [onTriggerModifiedInternal, parseHourMinutesSecondsToSeconds, state]
  );

  const getEndClockComponent = useCallback(() => {
    const [hour, minute] = parseSecondsToHourMinutesSeconds(
      state.trigger_data?.duration || 0
    );
    console.log(hour, minute);
    return (
      <Clock
        SetHour={onEndHourMinuteChanges}
        minMinutes={0}
        initHour={`${hour}`.padStart(2, "0")}
        initMinute={`${minute}`.padStart(2, "0")}
        title={<div className="HourStartIn">Duración:</div>}
        maxHours={23}
        maxMinutes={59}
        maxMinutesWhenMaxHoursReached={59}
        onMaxHourSetMinutesToMax={true}
        applyMaxMinutsOnlyWhenMaxHoursReached={true}
        enabled={true}
      />
    );
  }, [
    onEndHourMinuteChanges,
    parseSecondsToHourMinutesSeconds,
    state.trigger_data?.duration,
  ]);

  const onDaysChanged = useCallback(
    (days) => {
      const newState = {
        ...state,
        trigger_data: { ...state.trigger_data, modified: true, days },
      };
      setState(newState);
      onTriggerModifiedInternal(newState.trigger_data);
    },
    [onTriggerModifiedInternal, state]
  );

  const onDescriptionChange = useCallback(
    (description) => {
      const newState = {
        ...state,
        trigger_data: { ...state.trigger_data, modified: true, description },
      };
      setState(newState);
      onTriggerModifiedInternal(newState.trigger_data);
    },
    [onTriggerModifiedInternal, state]
  );

  const onWeeklyGracePeriodChange = useCallback(
    (weeklyActivationGracePeriod) => {
      const newState = {
        ...state,
        trigger_data: {
          ...state.trigger_data,
          modified: true,
          weeklyActivationGracePeriod,
        },
      };
      setState(newState);
      onTriggerModifiedInternal(newState.trigger_data);
    },
    [onTriggerModifiedInternal, state]
  );

  const isValidGracePeriod = useCallback((weeklyActivationGracePeriod) => {
    const number = Number(weeklyActivationGracePeriod)
    return (
      Number.isInteger(number) ||
      weeklyActivationGracePeriod === undefined ||
      weeklyActivationGracePeriod === null ||
      weeklyActivationGracePeriod === ""
    );
  }, []);

  const onActiveChange = useCallback(
    (active) => {
      const newState = {
        ...state,
        trigger_data: { ...state.trigger_data, modified: true, active },
      };
      setState(newState);
      onTriggerModifiedInternal(newState.trigger_data);
    },
    [onTriggerModifiedInternal, state]
  );

  const onWeeklyChecksChange = useCallback(
    (weeklyChecks) => {
      const newState = {
        ...state,
        trigger_data: { ...state.trigger_data, modified: true, weeklyChecks },
      };
      setState(newState);
      onTriggerModifiedInternal(newState.trigger_data);
    },
    [onTriggerModifiedInternal, state]
  );

  const onLoopChange = useCallback(
    (loop) => {
      const newState = {
        ...state,
        trigger_data: { ...state.trigger_data, modified: true, loop },
      };
      setState(newState);
      onTriggerModifiedInternal(newState.trigger_data);
    },
    [onTriggerModifiedInternal, state]
  );
  //#endregion

  //#region useEffect

  useEffect(() => {
    loadDataFromTrigger(trigger);
  }, [loadDataFromTrigger, trigger]);

  //#endregion

  return (
    <>
      <div className="ViewInterfacePrograms">
        {/*     <div className="TitleProgram">
              Configuración del programa {alldata?.index + 1}:
            </div> */}
        <div className="ViewInterfaceProgramsPart1">
          <div className="InputnameProgram">
            <div className="titleform programName">Nombre del programa:</div>
            <div className="NameKeyProgramF">
              <Input
                type="text"
                placeholder={`Programa ${state.trigger_data?.id || "nuevo"}`}
                value={state.trigger_data?.description || ""}
                className="bg-white NameKeyProgram"
                onChange={(e) => onDescriptionChange(e.target.value)}
                tabIndex={-1}
                id={"nameprogram"}
              />
            </div>
          </div>
          <div className="InputnameProgram">
            <ToggleWithSync
              title={"Activo"}
              checked={
                state.trigger_data?.active === undefined
                  ? true
                  : state.trigger_data?.active
              }
              onChange={onActiveChange}
            />
          </div>

          <div className="InputnameProgram">
            <ToggleWithSync
              title={"Alarmas"}
              checked={state.trigger_data?.weeklyChecks}
              onChange={onWeeklyChecksChange}
            />
          </div>

          {state.trigger_data?.weeklyChecks && (
            <div className="InputnameProgram">
              <div className="titleform">
                Márgen (segundos) para saltar alarma de un programa:
              </div>
              <div className="NameKeyProgramF">
                <Input
                  className="bg-white NameKeyProgram"
                  type="number"
                  placeholder="Segundos"
                  name="trigger-config-grace-period"
                  id="trigger-config-grace-period"
                  onChange={(e) => onWeeklyGracePeriodChange(e.target.value)}
                  value={state.trigger_data?.weeklyActivationGracePeriod || ""}
                  invalid={
                    !isValidGracePeriod(
                      state.trigger_data?.weeklyActivationGracePeriod
                    )
                  }
                />
              </div>
            </div>
          )}
        </div>

        <div className="ViewInterfaceProgramsPart2">
          {/*   <hr className="Barra" /> */}

          <div className="SelectTimeProgram">
            <div
              hidden={state.state !== states.ERROR_PROGRAM_24H}
              className="Error"
            >
              El programa no puede durar más de 24H.
            </div>
            {getClockComponent()}
          </div>
        </div>

        {allowLoopOption && (
          <>
            <div className="InputnameProgram">
              <ToggleWithSync
                title={"Cíclico"}
                checked={
                  state.trigger_data?.loop === undefined
                    ? false
                    : state.trigger_data?.loop
                }
                onChange={onLoopChange}
              />
            </div>
            {state.trigger_data?.loop && (
              <div className="SelectTimeProgram">{getEndClockComponent()}</div>
            )}
          </>
        )}

        {/*   <hr className="Barra" /> */}
        <div className="DaysComponent">
          <div
            hidden={state.state !== states.ERROR_DAYS_NOT_SELECTED}
            className="Error"
          >
            Debes de seleccionar al menos un día.
          </div>

          <Days
            SetDays={onDaysChanged}
            initDays={state.trigger_data?.days || 0}
            enabled={true}
            WarningMessage={() => {}}
          />
        </div>
        {/*   <hr className="Barra" /> */}
      </div>

      {/** Linea arriba de los botones para mostrar los errores que puedan surgir por la interacción del usuario: */}

      <div
        hidden={state.state !== states.ERROR_TOO_MUCH_ACTIVATIONS}
        className="Error"
      >
        Debe seleccionar las salidas que se deben activar.
      </div>

      <div className="ButtonsPrograms">
        <div className="Button Secondary" onClick={(e) => onSetupActivations()}>
          Programar salidas
        </div>
      </div>

      <div className="Buttons">
        {canBeSaved() ? (
          <div className="ButtonOffAll" onClick={(e) => onSaveInternal()}>
            Guardar
          </div>
        ) : (
          <div className="ButtonOffAll" disabled>
            Guardar
          </div>
        )}

        {state.trigger_data?.id > 0 && (
          <div
            className="ButtonOffAll Danger"
            onClick={(e) => onDeleteInternal()}
          >
            Eliminar
          </div>
        )}
      </div>
    </>
  );
};
