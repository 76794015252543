import React, { useCallback, useState, useEffect } from "react";
import { projectsConstants } from "../../../../_constants/projects.constants";
import { useTriggers } from "../../../../_services/triggers.service";
import { DangerComponent } from "../../../DangerComponent/DangerComponent";
import { CheckComponent } from "../../Airframe/CheckComponent/CheckComponents";
import { HorizontalSelector } from "../../Airframe/HorizontalSelector/HorizontalSelector";
import { Spinner } from "../../Airframe/Spinner/Spinner";
import { TriggerSubprogramForm } from "./TriggerSubprogramForm";
import { TriggerSubprogramsView } from "./TriggerSubprogramsView";
import { TriggerFormView } from "./TriggerFormView";
import { PopUpC } from "../../Airframe/PopUp/PopUp";
import { usePublisher } from "../../../../context/publish-context";
import { MESSAGE_TYPE_ACTION } from "../../../../_constants/messageType.constants";
import uuid from "uuid/v4";
import { TerminalDeviceTriggerStatesHandler } from "../TerminalDeviceTriggerStatesHandler";
import { isTerminalDeviceValidForDatetimeTriggersWithLoop } from "../Fichas/Triggers/TerminalDeviceTriggerChecker";

export const DatetimeTriggersView = (props) => {
  const { terminalDevice, onBack, triggerId, projectId = projectsConstants.master_outputs.id } = props;
  const triggersService = useTriggers();
  const { publish } = usePublisher();

  const states = {
    IDLE: "IDLE",
    LOADING_TRIGGERS: "LOADING_TRIGGERS",
    LOADED_TRIGGERS: "LOADED_TRIGGERS",
    ERROR_LOADING_TRIGGERS: "ERROR_LOADING_TRIGGERS",

    SAVING_TRIGGER: "SAVING_TRIGGER",
    SAVED_TRIGGER: "SAVED_TRIGGER",
    ERROR_SAVING_TRIGGER: "ERROR_SAVING_TRIGGER",

    DELETE_TRIGGER_ASK_CONFIRMATION: "DELETE_TRIGGER_ASK_CONFIRMATION",
    DELETING_TRIGGER: "DELETING_TRIGGER",
    DELETED_TRIGGER: "DELETED_TRIGGER",
    ERROR_DELETING_TRIGGER: "ERROR_DELETING_TRIGGER",

    CANT_ADD_MORE_TRIGGERS: "CANT_ADD_MORE_TRIGGERS",
    CANT_ADD_MORE_ACTIVATIONS: "CANT_ADD_MORE_ACTIVATIONS",
  };

  const steps = {
    START_CONFIG: "START_CONFIG",
    ACTIVATIONS: "ACTIVATIONS",
  };

  const initState = {
    state: states.IDLE,
    savingState: states.IDLE,
    step: steps.START_CONFIG,
    selectedSubprogram: undefined,
    triggerToDelete: undefined,
    error: undefined,
  };
  const [state, setState] = useState(initState);
  const [triggers, setTriggers] = useState({});

  //#region maps
  const parseTriggersToElements = useCallback(
    (trigger) => {
      return triggers.map((trigger) => ({
        id: trigger?.id,
        title: "PROG",
        text: trigger?.id || "X",
        isSelected: trigger?.selected,
        modified: trigger?.modified,
        source: trigger,
        isInSync: trigger?.notified,
      }));
    },
    [triggers]
  );

  //#endregion

  //#region ACTIVATIONS TO SUBPROGRAM

  const getSubprogramUnitsLimitFromChain = useCallback(
    (activations, activationId) => {
      const activation = activations.filter(
        (act) => act?.id === activationId
      )[0];
      if (activation) {
        return (
          activation.unitsLimit +
          getSubprogramUnitsLimitFromChain(activations, activation.prevId)
        );
      }
      return 0;
    },
    []
  );

  const parseActivationsToSubprograms = useCallback(
    (trigger) => {
      if (trigger?.activations instanceof Array) {
        let currentDateTime = new Date(trigger?.startTimestamp);

        /*trigger.subprograms = groupActivationsBySourceId(
          trigger.activations.sort(
            (first, second) => first?.sourceId - second?.sourceId
          )
        )
          .map(parseActivationsToSubprogram)*/
        trigger.subprograms =  trigger.activations.sort(
          (first, second) => first?.sourceId - second?.sourceId
          )
          .map((subprogram) => {
            let newSubprogram = {
              ...subprogram,
              startDateTime: currentDateTime,
              endDateTime: new Date(
                currentDateTime.getTime() + subprogram.unitsLimit
              ),
            };
            currentDateTime = newSubprogram.endDateTime;
            return newSubprogram;
          });
      } else {
        trigger.subprograms = [];
      }
    },
    []
  );

  const parseTriggerActivationsToSubprograms = useCallback(
    (triggers) => {
      triggers
        .filter(
          (trigger) =>
            trigger.type === projectsConstants.global.triggers.types.program
        )
        .forEach(parseActivationsToSubprograms);
    },
    [parseActivationsToSubprograms]
  );
  //#endregion

  //#region SUBPROGRAMS TO ACTIVATIONS
  const getPulsesForOutput = useCallback((output) => {
    if (output?.activationParams) {
      const activationParams = JSON.parse(output.activationParams);

      return [activationParams.first_pulse, activationParams.last_pulse];
    }
    return [1000, 1000];
  }, []);

  const processSubprogramForPulsesOutput = useCallback(
    (output, subprogram, withPolarity, isLoop) => {
      const [firstPulse, lastPulse] = getPulsesForOutput(output);
      const outputs = 1 << (output.output - 1);
      if (firstPulse + lastPulse < subprogram.unitsLimit) {
        subprogram.unitsLimit -= firstPulse + lastPulse;
      }

      if (isLoop) {
        return [
          {
            ...subprogram,
            unitsLimit: firstPulse,
            outputs,
            terminalDeviceOutputs: terminalDevice?.id,
            pwm: 100,
            unitControl: "TIME",
            cudId: 0,
            polarity: "FROM_P_TO_N",
            finalActivation: false,
            source: "SUBPROGRAM",
            sourceId: subprogram?.id,
            loop: false,
          },
          {
            ...subprogram,
            unitsLimit: lastPulse,
            outputs,
            terminalDeviceOutputs: terminalDevice?.id,
            pwm: 100,
            unitControl: "TIME",
            cudId: 0,
            polarity: "FROM_P_TO_N",
            finalActivation: true,
            source: "SUBPROGRAM",
            sourceId: subprogram?.id,
            loop: true,
          },
        ];
      }

      return [
        {
          ...subprogram,
          unitsLimit: firstPulse,
          outputs,
          terminalDeviceOutputs: terminalDevice?.id,
          pwm: 100,
          unitControl: "TIME",
          cudId: 0,
          polarity: "FROM_P_TO_N",
          finalActivation: false,
          source: "SUBPROGRAM",
          sourceId: subprogram?.id,
          loop: false,
        },
        {
          ...subprogram,
          outputs,
          terminalDeviceOutputs: terminalDevice?.id,
          pwm: 0,
          unitControl: "TIME",
          cudId: 0,
          polarity: "FROM_P_TO_N",
          finalActivation: false,
          source: "SUBPROGRAM",
          sourceId: subprogram?.id,
          loop: false,
        },
        {
          ...subprogram,
          unitsLimit: lastPulse,
          outputs,
          terminalDeviceOutputs: terminalDevice?.id,
          pwm: 100,
          unitControl: "TIME",
          cudId: 0,
          polarity: withPolarity ? "FROM_N_TO_P" : "FROM_P_TO_N",
          finalActivation: true,
          source: "SUBPROGRAM",
          sourceId: subprogram?.id,
          loop: false,
        },
      ];
    },
    [getPulsesForOutput, terminalDevice?.id]
  );

  const processSubprogramForOutput = useCallback(
    (outputs, outputType, subprogram) => {
      // De fake activation obtenemos el sourceId que es el que identifica al conjunto de activaciones

      switch (outputType) {
        case projectsConstants.global.activations.pulses:
          // AUN podríamos mejorar agrupando por firstPulse y lastPulse.
          return outputs.flatMap((output) =>
            processSubprogramForPulsesOutput(output, subprogram, false, false)
          );
        case projectsConstants.global.activations.pulses_per_minute:
          return outputs.flatMap((output) =>
            processSubprogramForPulsesOutput(output, subprogram, false, true)
          );
        case projectsConstants.global.activations.pulses_with_polarity:
          return outputs.flatMap((output) =>
            processSubprogramForPulsesOutput(output, subprogram, true, false)
          );
        default:
          return [
            {
              ...subprogram,
              outputs: outputs instanceof Array ? outputs.reduce(
                (outputs, currentOutput) =>
                  (outputs |= 1 << (currentOutput.output - 1)),
                0
              ) :  outputs,
              terminalDeviceOutputs: terminalDevice?.id,
              pwm: 100,
              unitControl: "TIME",
              cudId: 0,
              polarity: "FROM_P_TO_N",
              finalActivation: false,
              source: "SUBPROGRAM",
              sourceId: subprogram?.id,
              loop: false,
            },
          ];
      }
    },
    [processSubprogramForPulsesOutput, terminalDevice?.id]
  );

  const parseSubprogramsToTriggerActivation = useCallback(
    (subprograms) => {
      if (subprograms && terminalDevice?.outputs) {
        const activations = subprograms
          .map(subprogram => processSubprogramForOutput(subprogram.outputs, projectsConstants.global.activations.continuous, subprogram)[0])
          .map((activation, index) => {

            const id = (activation.offset > 0 && activation.id > 0) ? activation.id : index + 1;
            const prevId = (activation.offset > 0) ? activation.prevId : index
            
            return {
              ...activation,
              id,
              prevId
            }
          })

        return activations;
      }
      return [];
    },
    [processSubprogramForOutput, terminalDevice.outputs]
  );

  const parseSubprogramsToTriggerActivations = useCallback(
    (trigger) => {
      if (trigger.subprograms instanceof Array) {
        trigger.activations = parseSubprogramsToTriggerActivation(
          trigger.subprograms
        );
      }
    },
    [parseSubprogramsToTriggerActivation]
  );
  //#endregion

  //#region  methods
  const getTriggerSelected = useCallback(() => {
    if (triggers instanceof Array) {
      return triggers.filter((trigger) => trigger.selected)[0];
    }
  }, [triggers]);

  const getNewTrigger = useCallback(() => {
    const startTimestamp = new Date();
    startTimestamp.setHours(12, 0, 0, 0);
    const trigger = {
      days: 0,
      startTimestamp: startTimestamp.toISOString(),
      selected: true,
      modified: true,
      active: true,
      type: projectsConstants.global.triggers.types.program,
      weeklyChecks: true,
      weeklyActivationGracePeriod: 60
    };
    parseActivationsToSubprograms(trigger);

    return trigger;
  }, [parseActivationsToSubprograms]);

  const onTriggersModifiedByMQTTMessage = useCallback(triggers => {
    setTriggers([...triggers]);
  }, [])


  const isTerminalDeviceMMS_HW_LT3_AllowedToAddNewTrigger = useCallback((terminalDevice, triggers) => {
    return parseInt(terminalDevice.hardwareVersion) < 3 
      && triggers?.length < projectsConstants.global.triggers.MMS_HW1_MAX_TRIGGERS_SIZE
  }, [])
  
  const isTerminalDeviceMMS_HW_EGT3_AllowedToAddNewTrigger = useCallback((terminalDevice, triggers) => {
    return parseInt(terminalDevice.hardwareVersion) >= 3
      && triggers?.length < projectsConstants.global.triggers.MMS_HW3_MAX_TRIGGERS_SIZE 
  }, []);
  
  const isMmsX5AllowedToAddNewTrigger = useCallback((deviceId, triggersLength) => {
    return deviceId === projectsConstants.global.devices.MMS_X5
        && triggersLength < projectsConstants.global.triggers.MMS_X5_HW1_MAX_TRIGGERS_SIZE
  }, [])

  const isLscX1AllowedToAddNewTrigger = useCallback((terminalDevice, deviceId, triggersLength) => {
    return deviceId === projectsConstants.global.devices.LSC_V1
        && parseInt(terminalDevice.hardwareVersion) >= 1
        && triggersLength < projectsConstants.global.triggers.LSC_HW1_MAX_TRIGGERS_SIZE
  }, [])

  const isLscX4AllowedToAddNewTrigger = useCallback((terminalDevice, deviceId, triggersLength) => {
    return deviceId === projectsConstants.global.devices.LSC_V4
        && parseInt(terminalDevice.hardwareVersion) >= 1
        && triggersLength < projectsConstants.global.triggers.LSC_V4_HW1_MAX_TRIGGERS_SIZE
  }, [])

  const isAllowedToAddNewTrigger = useCallback(() => {
    const deviceId = terminalDevice?.device?.id;
    return (projectsConstants.global.devices.MMS === deviceId 
      && (isTerminalDeviceMMS_HW_LT3_AllowedToAddNewTrigger(terminalDevice, triggers) || isTerminalDeviceMMS_HW_EGT3_AllowedToAddNewTrigger(terminalDevice, triggers)))
      || (projectsConstants.global.devices.SIO === deviceId && triggers.length < projectsConstants.global.triggers.SIO_HW3_MAX_TRIGGERS_SIZE)
      || isMmsX5AllowedToAddNewTrigger(deviceId, triggers.length)
      || isLscX1AllowedToAddNewTrigger(terminalDevice, deviceId, triggers.length)
      || isLscX4AllowedToAddNewTrigger(terminalDevice, deviceId, triggers.length)
  
  }, [terminalDevice, isTerminalDeviceMMS_HW_LT3_AllowedToAddNewTrigger, triggers, isTerminalDeviceMMS_HW_EGT3_AllowedToAddNewTrigger, isMmsX5AllowedToAddNewTrigger, isLscX1AllowedToAddNewTrigger, isLscX4AllowedToAddNewTrigger]);


  const isTerminalDeviceMMS_HW_LT3_AllowedToAddNewActivation = useCallback((terminalDevice, trigger) => {
    return parseInt(terminalDevice.hardwareVersion) < 3 
      && trigger?.subprograms?.length < projectsConstants.global.triggers.MMS_HW1_MAX_TRIGGERS_ACTIVATIONS_SIZE
  }, [])
  
  const isTerminalDeviceMMS_HW_EGT3_AllowedToAddNewActivation = useCallback((terminalDevice, trigger) => {
    return parseInt(terminalDevice.hardwareVersion) >= 3
      && trigger?.subprograms?.length < projectsConstants.global.triggers.MMS_HW3_MAX_TRIGGERS_ACTIVATIONS_SIZE
  }, []);
  
  const isMmsX5AllowedToAddNewActivation = useCallback((deviceId, triggersActivationLength) => {
    return deviceId === projectsConstants.global.devices.MMS_X5
        && triggersActivationLength < projectsConstants.global.triggers.MMS_X5_HW1_MAX_TRIGGERS_ACTIVATIONS_SIZE
  }, [])

  const isLscX1AllowedToAddNewActivation = useCallback((terminalDevice, deviceId, triggersActivationLength) => {
    return deviceId === projectsConstants.global.devices.LSC_V1
        && parseInt(terminalDevice.hardwareVersion) >= 1
        && triggersActivationLength < projectsConstants.global.triggers.LSC_HW1_MAX_TRIGGERS_ACTIVATIONS_SIZE
  }, [])

  const isLscX4AllowedToAddNewActivation = useCallback((terminalDevice, deviceId, triggersActivationLength) => {
    return deviceId === projectsConstants.global.devices.LSC_V4
        && parseInt(terminalDevice.hardwareVersion) >= 1
        && triggersActivationLength < projectsConstants.global.triggers.LSC_V4_HW1_MAX_TRIGGERS_ACTIVATIONS_SIZE
  }, [])

  const isAllowedToAddNewActivation = useCallback(() => {
    const deviceId = terminalDevice?.device?.id;
    const trigger = getTriggerSelected();
    return (projectsConstants.global.devices.MMS === deviceId 
      && (isTerminalDeviceMMS_HW_LT3_AllowedToAddNewActivation(terminalDevice, trigger) || isTerminalDeviceMMS_HW_EGT3_AllowedToAddNewActivation(terminalDevice, trigger)))
      || (projectsConstants.global.devices.SIO === deviceId && trigger?.subprograms?.length < projectsConstants.global.triggers.SIO_HW3_MAX_TRIGGERS_ACTIVATIONS_SIZE)
      || isMmsX5AllowedToAddNewActivation(deviceId, trigger?.subprograms?.length)
      || isLscX1AllowedToAddNewActivation(terminalDevice, deviceId, trigger?.subprograms?.length)
      || isLscX4AllowedToAddNewActivation(terminalDevice, deviceId, trigger?.subprograms?.length)
  
  }, [terminalDevice, getTriggerSelected, isTerminalDeviceMMS_HW_LT3_AllowedToAddNewActivation, isTerminalDeviceMMS_HW_EGT3_AllowedToAddNewActivation, isMmsX5AllowedToAddNewActivation, isLscX1AllowedToAddNewActivation, isLscX4AllowedToAddNewActivation]);

  //#endregion

  //#region onClick

  const publishSetTriggers = useCallback(() => {
    publish(projectsConstants.master_outputs.actions.common.settriggers, {
      type: MESSAGE_TYPE_ACTION,
      id: uuid(),
      data: {
        target_id: terminalDevice?.id
      }
    }, projectId);
  }, [publish, terminalDevice?.id, projectId])

  const publishDeletrTriggers = useCallback(() => {
    publish(projectsConstants.master_outputs.actions.common.deletetriggers, {
      type: MESSAGE_TYPE_ACTION,
      id: uuid(),
      data: {
        target_id: terminalDevice?.id
      }
    }, projectId);
  }, [publish, terminalDevice?.id, projectId])

  const onTriggerModified = useCallback(
    (trigger) => {
      if (triggers instanceof Array) {
        const indexOfTrigger = triggers.findIndex(
          (sourceTrigger) => sourceTrigger?.id === trigger?.id
        );
        if (indexOfTrigger < 0) {
          setTriggers([...triggers, trigger]);
        } else {
          triggers[indexOfTrigger] = { ...trigger };
          setTriggers([...triggers]);
        }
      }
      publishSetTriggers();
    },
    [publishSetTriggers, triggers]
  );

  const onTriggerCreated = useCallback(
    (trigger) => {
      if (triggers instanceof Array) {
        const indexOfSelected = triggers.findIndex(
          (trigger) => trigger.selected
        );

        if (indexOfSelected >= 0) {
          triggers[indexOfSelected] = trigger;
        } else {
          console.log("Error inesperado");
        }

        setTriggers([...triggers]);
      }
      publishSetTriggers();
    },
    [publishSetTriggers, triggers]
  );

  const onTriggerDeleted = useCallback(
    (trigger) => {
      trigger.deletedByUser = true;
      if (triggers instanceof Array) {
        const indexOfSelected = triggers.findIndex(
          (sourceTrigger) => sourceTrigger?.id === trigger?.id
        );

        if (indexOfSelected >= 0) {
          triggers.splice(indexOfSelected, 1)

          if(indexOfSelected - 1 >= 0){
            triggers.forEach((sourceTrigger, index) => sourceTrigger.selected = index === (indexOfSelected - 1))
           
          }else if(triggers.length > 0){
            triggers.forEach((sourceTrigger, index) => sourceTrigger.selected = index === indexOfSelected)
          }
          else{
            triggers.push(getNewTrigger())
          }

          setTriggers([...triggers])
          setState(current => ({...current, error: undefined})) // Reseteamos los errores.
        } else {
          console.log("Error inesperado");
        }

        publishDeletrTriggers();
      }
    },
    [getNewTrigger, publishDeletrTriggers, triggers]
  );

  const onBackInternal = useCallback(() => {
    if (onBack) {
      onBack();
    }
  }, [onBack]);

  const onTriggerClick = useCallback(
    (trigger) => {
      if (triggers instanceof Array) {
        triggers.forEach(
          (sourceTrigger) =>
            (sourceTrigger.selected = trigger?.id === sourceTrigger?.id)
        );
        setTriggers([...triggers]);
        setState((prev) => ({ ...prev, step: steps.START_CONFIG }));
      }
    },
    [steps.START_CONFIG, triggers]
  );

  const onAddClick = useCallback(() => {
    const selectedTrigger = getTriggerSelected();

    if (triggers instanceof Array && selectedTrigger?.id > 0) {

      if(isAllowedToAddNewTrigger()){
        triggers.forEach((trigger) => (trigger.selected = false));
        triggers.push(getNewTrigger());
        setTriggers([...triggers]);
        setState((prev) => ({ ...prev, step: steps.START_CONFIG, error: undefined }));
      }
      else {
        setState(current => ({...current, error: states.CANT_ADD_MORE_TRIGGERS}));
      }
      
    }
  }, [getTriggerSelected, triggers, isAllowedToAddNewTrigger, getNewTrigger, steps.START_CONFIG, states.CANT_ADD_MORE_TRIGGERS]);

  const onSetupActivations = useCallback(() => {

    if(isAllowedToAddNewActivation()){
      setState((prev) => ({
        ...prev,
        selectedSubprogram: undefined,
        step: steps.ACTIVATIONS,
        error: undefined,
      }));
    }
    else {
      setState(current => ({...current, error: states.CANT_ADD_MORE_ACTIVATIONS}));
    }
    
  }, [steps.ACTIVATIONS, states.CANT_ADD_MORE_ACTIVATIONS, isAllowedToAddNewActivation]);

  const getCreateUpdateTriggerDTO = useCallback((trigger) => {
    const startDate = trigger?.startTimestamp ? new Date(trigger?.startTimestamp) : undefined
    let endDate;
    if(startDate){
      endDate = new Date(trigger?.startTimestamp)
      endDate.setTime(endDate.getTime() + ((trigger?.duration || 0) * 1000))
    }
    return {
      type: projectsConstants.global.triggers.types.program,
      setpoint: 0,
      operator: "WEEKLY",
      active: trigger?.active === undefined ? true : trigger?.active,
      activations: [...trigger?.activations],
      sensor: undefined,
      startTimestamp: trigger?.startTimestamp,
      endTimestamp: endDate?.toISOString() || undefined,
      loop: endDate ? trigger?.loop : false,
      days: trigger?.days || 0,
      description: trigger?.description || "",
      weeklyActivationGracePeriod: trigger?.weeklyActivationGracePeriod,
      weeklyChecks: trigger?.weeklyChecks || false
    };
  }, []);

  const onTriggerSave = useCallback(
    (trigger) => {
      if (trigger) {
        setState((prev) => ({ ...prev, savingState: states.SAVING_TRIGGER }));
        parseSubprogramsToTriggerActivations(trigger);
        const triggerDto = getCreateUpdateTriggerDTO(trigger);
        if (trigger?.id > 0) {
          // ACTUALIZAR
          triggersService
            .update(
              terminalDevice?.terminal,
              terminalDevice?.id,
              trigger.id,
              triggerDto
            )
            .then(
              (updatedTrigger) => {
                updatedTrigger.selected = true;
                updatedTrigger.duration = trigger.duration;
                parseActivationsToSubprograms(updatedTrigger);
                onTriggerModified(updatedTrigger);
                setState((prev) => ({
                  ...prev,
                  savingState: states.SAVED_TRIGGER,
                }));
              },
              (error) => {
                setState((prev) => ({
                  ...prev,
                  savingState: states.ERROR_SAVING_TRIGGER,
                }));
              }
            );
        } else {
          // INSERTAR
          triggersService
            .save(terminalDevice?.terminal, terminalDevice?.id, triggerDto)
            .then(
              (createdTrigger) => {
                createdTrigger.selected = true;
                createdTrigger.duration = trigger.duration;
                parseActivationsToSubprograms(createdTrigger);
                onTriggerCreated(createdTrigger);
                setState((prev) => ({
                  ...prev,
                  savingState: states.SAVED_TRIGGER,
                }));
              },
              (error) => {
                setState((prev) => ({
                  ...prev,
                  savingState: states.ERROR_SAVING_TRIGGER,
                }));
              }
            );
        }
      }
    },
    [
      getCreateUpdateTriggerDTO,
      onTriggerCreated,
      onTriggerModified,
      parseActivationsToSubprograms,
      parseSubprogramsToTriggerActivations,
      states.ERROR_SAVING_TRIGGER,
      states.SAVED_TRIGGER,
      states.SAVING_TRIGGER,
      terminalDevice?.id,
      terminalDevice?.terminal,
      triggersService,
    ]
  );

  const onTriggerDeleteAskConfirmation = useCallback(
    (trigger) => {
      setState((prev) => ({
        ...prev,
        savingState: states.DELETE_TRIGGER_ASK_CONFIRMATION,
        triggerToDelete: trigger,
      }));
    },
    [states.DELETE_TRIGGER_ASK_CONFIRMATION]
  );

  const onCancelDelete = useCallback(() => {
    setState((prev) => ({
      ...prev,
      savingState: states.LOADED_TRIGGERS,
      triggerToDelete: undefined,
    }));
  }, [states.LOADED_TRIGGERS]);

  const onTriggerDelete = useCallback(() => {
    const trigger = state.triggerToDelete;
    if (trigger) {
      setState((prev) => ({ ...prev, savingState: states.DELETING_TRIGGER }));
      if (trigger?.id > 0) {
        // ACTUALIZAR
        triggersService
          .deleteTrigger(
            terminalDevice?.terminal,
            terminalDevice?.id,
            trigger.id
          )
          .then(
            (deletedTigger) => {
              onTriggerDeleted(deletedTigger);
              setState((prev) => ({
                ...prev,
                savingState: states.DELETED_TRIGGER,
              }));
            },
            (error) => {
              setState((prev) => ({
                ...prev,
                savingState: states.ERROR_DELETING_TRIGGER,
              }));
            }
          );
      }
    }
  }, [
    onTriggerDeleted,
    state.triggerToDelete,
    states.DELETED_TRIGGER,
    states.DELETING_TRIGGER,
    states.ERROR_DELETING_TRIGGER,
    terminalDevice?.id,
    terminalDevice?.terminal,
    triggersService,
  ]);
  //#endregion

  //#region onClick TriggerActivationForm
  const onTriggerSubprogramSave = useCallback(
    (subprogram) => {
      const triggerSelected = getTriggerSelected();
      if (triggerSelected && subprogram) {
        const subprogramIndex = triggerSelected.subprograms.findIndex(
          (sourceSubp) => sourceSubp.id === subprogram?.id
        );

        if (subprogramIndex < 0) {
          triggerSelected.subprograms.push({
            ...subprogram,
            prevId: triggerSelected.subprograms.length,
            id: triggerSelected.subprograms.length + 1,
          });
        } else {
          triggerSelected.subprograms[subprogramIndex] = subprogram;
        }
      }

      triggerSelected.modified = true;
      onTriggerModified(triggerSelected);
      setState((prev) => ({ ...prev, step: steps.START_CONFIG }));
    },
    [getTriggerSelected, onTriggerModified, steps.START_CONFIG]
  );

  const onTriggerActivationBack = useCallback(() => {
    setState((prev) => ({ ...prev, step: steps.START_CONFIG }));
  }, [steps.START_CONFIG]);
  //#endregion

  //#region onClick TriggerActivationsView
  const onReorderedTriggerSubprograms = useCallback(
    (subprograms) => {
      const triggerSelected = getTriggerSelected();
      if (triggerSelected) {
        triggerSelected.subprograms = subprograms;
        triggerSelected.modified = true;
        setTriggers((prev) => [...prev]);
      }
    },
    [getTriggerSelected]
  );
  const onEditTriggerSubprogram = useCallback(
    (subprogram) => {
      setState((prev) => ({
        ...prev,
        selectedSubprogram: subprogram,
        step: steps.ACTIVATIONS,
      }));
    },
    [steps.ACTIVATIONS]
  );
  const onDeleteTriggerSubprogram = useCallback(
    (subprogram) => {
      const trigger = getTriggerSelected();
      if (subprogram && trigger?.subprograms instanceof Array) {
        trigger.subprograms = trigger.subprograms.filter(
          (sourceSubp) => sourceSubp?.id !== subprogram?.id
        );

        trigger.subprograms.forEach(activation => {
          if(activation.prevId !== 0 
            && activation.offset === 0
            && activation.prevId === subprogram.id){

              const newPrevId = subprogram.id > 0 ? subprogram.id - 1 : 0;
              activation.prevId = newPrevId
          }
        })

        trigger.modified = true;
        onTriggerModified(trigger);
      }
    },
    [getTriggerSelected, onTriggerModified]
  );
  //#endregion

  //#region components
  const getTriggersSelector = useCallback(() => {
    const elements = parseTriggersToElements();

    if (elements.length > 0) {
      return (
        <HorizontalSelector
          elements={elements}
          onElementClick={onTriggerClick}
          onAddClick={onAddClick}
          disabled={state.savingState === states.SAVING_TRIGGER || state.savingState === states.DELETING_TRIGGER}
        ></HorizontalSelector>
      );
    }

    return <></>;
  }, [onAddClick, onTriggerClick, parseTriggersToElements, state.savingState, states.DELETING_TRIGGER, states.SAVING_TRIGGER]);

  const getTriggerActivationDefaultStartDateTime = useCallback(() => {
    const trigger = getTriggerSelected();
    if (!trigger?.subprograms?.length) {
      return new Date(trigger.startTimestamp);
    }
    return trigger?.subprograms[trigger.subprograms.length - 1].endDateTime;
  }, [getTriggerSelected]);
  //#endregion

  //#region useEffect

  useEffect(() => {
    if (state?.state === states.IDLE) {
      setState((prev) => {
        return { ...prev, state: states.LOADING_TRIGGERS };
      });
      triggersService.getAll(terminalDevice?.terminal, terminalDevice?.id).then(
        (triggers) => {
          triggers = triggers.filter(
            (trigger) =>
              !trigger?.deletedByUser &&
              trigger?.type === projectsConstants.global.triggers.types.program
          )
          .map(trigger => {
            if(trigger.startTimestamp && trigger.endTimestamp) {
              const oldStartDateTime = new Date(trigger.startTimestamp)
              const oldEndDate = new Date(trigger.endTimestamp)
              const now = new Date();

              const startDate = oldStartDateTime
                ? new Date(now.getFullYear(), now.getMonth(), now.getDate(), 
                    oldStartDateTime.getHours(), oldStartDateTime.getMinutes(), 
                    oldStartDateTime.getSeconds(), oldStartDateTime.getMilliseconds())
                : undefined;

              const endDate = oldEndDate
                ? new Date(now.getFullYear(), now.getMonth(), now.getDate(), 
                    oldEndDate.getHours(), oldEndDate.getMinutes(), 
                    oldEndDate.getSeconds(), oldEndDate.getMilliseconds())
                : undefined;

              trigger.duration = (endDate?.getTime() - startDate?.getTime()) / 1000
              trigger.startTimestamp = startDate.toISOString()
              trigger.endTimestamp = endDate.toISOString()
            }

            return trigger
          })

          if (triggers instanceof Array && triggers.length > 0) {
            parseTriggerActivationsToSubprograms(triggers);
            let selTrigger = triggers[0];
            if (triggerId) {
              selTrigger = triggers.filter(trigger => trigger.id === triggerId)[0];
            }
            
            if (selTrigger) {
              selTrigger.selected = true;
            }
          } else {
            triggers = [getNewTrigger()];
          }

          setTriggers(triggers);
          setState((prev) => {
            return { ...prev, state: states.LOADED_TRIGGERS };
          });
        },
        (error) => {
          setState((prev) => {
            return { ...prev, state: states.ERROR_LOADING_TRIGGERS };
          });
        }
      );
    }
  }, [
    getNewTrigger,
    parseActivationsToSubprograms,
    parseTriggerActivationsToSubprograms,
    state,
    states.ERROR_LOADING_TRIGGERS,
    states.IDLE,
    states.LOADED_TRIGGERS,
    states.LOADING_TRIGGERS,
    terminalDevice?.id,
    terminalDevice?.terminal,
    triggerId,
    triggersService,
  ]);

  //#region

  return (
    <>
      <TerminalDeviceTriggerStatesHandler triggers={triggers} onStateReceived={onTriggersModifiedByMQTTMessage}></TerminalDeviceTriggerStatesHandler>
      <div className="fatherPrograms">
        <div className="TitleTerminaldevices">
          <div className="Titleprograms" onClick={(e) => onBackInternal()}>
            {onBack && (
              <div className="Icoback">
                <div className="fa fa-fw fa-mail-reply"></div>
              </div>
            )}
            <div className="TitleTerminaldevices2">
              {terminalDevice?.description ||
                `${terminalDevice?.id}-${terminalDevice?.device?.id}`}
            </div>
          </div>
        </div>
        <div className="classprogramsfather">
          <div className="ProgramsView">
            {state.state === states.LOADING_TRIGGERS && (
              <div className="FixScroll">
                <Spinner></Spinner>
              </div>
            )}
            {state.state === states.LOADED_TRIGGERS && getTriggersSelector()}
          </div>

          {state.savingState === states.SAVED_TRIGGER && (
            <CheckComponent
              message={<h4>Guardado correctamente.</h4>}
            ></CheckComponent>
          )}
          {state.savingState === states.ERROR_SAVING_TRIGGER && (
            <DangerComponent
              message={<h4>Error al guardar.</h4>}
            ></DangerComponent>
          )}

          {state.savingState === states.DELETE_TRIGGER_ASK_CONFIRMATION && (
            <PopUpC
              activate={true}
              deactivatepopup={onCancelDelete}
              content={
                <div className="contentpopup">
                  <div className="textapp">
                    Estas seguro de eliminar el programa?
                  </div>{" "}
                  <div
                    className="ButtonConfirmPopup"
                    onClick={(e) => onTriggerDelete()}
                  >
                    Confirmar
                  </div>
                </div>
              }
            />
          )}

          {state.error === states.CANT_ADD_MORE_TRIGGERS && <DangerComponent message={<h4>No se pueden añadir más programas. Límite alcanzado.</h4>}></DangerComponent>}
          {state.error === states.CANT_ADD_MORE_ACTIVATIONS && <DangerComponent message={<h4>No se pueden programar más salidas. Límite alcanzado.</h4>}></DangerComponent>}

          {state.savingState === states.SAVING_TRIGGER && <Spinner></Spinner>}
          {state.step === steps.START_CONFIG &&
            state.savingState !== states.SAVING_TRIGGER && (
              <>
                {getTriggerSelected()?.notified === false && (
                  <div className="head_TsmConfigure">
                    <div>Sincronizando con el dispositivo...</div>
                    <Spinner></Spinner>
                  </div>
                )}
                <TriggerSubprogramsView
                  trigger={getTriggerSelected()}
                  onReorderedTriggerSubprograms={onReorderedTriggerSubprograms}
                  onEditTriggerSubprogram={onEditTriggerSubprogram}
                  onDeleteTriggerSubprogram={onDeleteTriggerSubprogram}
                />
                <TriggerFormView
                  trigger={getTriggerSelected()}
                  onSave={onTriggerSave}
                  onDelete={onTriggerDeleteAskConfirmation}
                  onTriggerModified={onTriggerModified}
                  onSetupActivations={onSetupActivations}
                  allowLoopOption={isTerminalDeviceValidForDatetimeTriggersWithLoop(terminalDevice)}
                ></TriggerFormView>
              </>
            )}
          {state.step === steps.ACTIVATIONS && (
            <TriggerSubprogramForm
              terminalDevice={terminalDevice}
              subprogram={state.selectedSubprogram}
              onBack={onTriggerActivationBack}
              onSave={onTriggerSubprogramSave}
              defaultStartDateTime={getTriggerActivationDefaultStartDateTime()}
            ></TriggerSubprogramForm>
          )}
        </div>
      </div>
    </>
  );
};
