import React from "react";
import Toggle from "react-toggle";
import Slider from "rc-slider";

export const TerminalDeviceOutputDigitalSensorConfig = props => {
    const {title, shouldCheck, flowMargin, onShouldCheckChange, onMarginChange} = props
    return (
    <div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div className="Title_Descripction">{title}</div>
        <Toggle
            id="toogle-digitalSensorShouldCheckOverflow"
            checked={shouldCheck ?? false}
            icons={false}
            onChange={onShouldCheckChange}
            className="toggle"
        />
        </div>
        {shouldCheck && <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div className="Title_Descripction">Margen: {flowMargin ?? 5}%:</div>
            <div className={"rangeSliderWrap"}>
                <Slider
                enabled={true}
                onChange={onMarginChange}
                value={flowMargin ?? 5}
                min={5}
                max={100}
                step={5}
                />
            </div>
        </div>
        }
    </div>
    )
}